// 严凯 V2.2.0
<template>
  <a-card class="wrapper" title="部门列表">
    <template #extra>
      <!-- <a-button class="comfirm btn" @click="$router.push('/businessManage/department/add')" v-btn="'add'" -->
      <a-button class="comfirm btn" @click="openModal('add')" v-btn="'add'"><PlusOutlined />添加部门</a-button>
      <a-button @click="openModal('export')" v-btn="'import'" class="btn" style="margin-left: 16px">批量导入</a-button>
    </template>
    <div class="wrap">
      <section class="tree-box">
        <h3 class="company-title" :title="companyName">{{ companyName }}</h3>
        <tree-virtual-list :size="40" :remain="departmentList.length" :list="departmentList" :isReload="treeReload" />
      </section>
      <section class="table-box">
        <section class="form-style" style="padding: 0">
          <a-form layout="inline">
            <a-form-item label="" @click="clickFocus" style="margin-right: 16px">
              <a-input
                class="sec-input"
                allowClear
                :maxlength="20"
                v-model:value="searchVal"
                placeholder="请输入部门名称"
                ref="inputbox"
              >
                <template #prefix>
                  <img src="@/assets/svg/search.svg" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item class="form-btns" style="float: none">
              <a-button class="form-btn" @click="searchList" style="margin-left: 0">查询</a-button>
              <!-- <a-button class="form-btn" @click="openModal('export')" v-btn="'import'">批量导入</a-button> -->
              <!-- <a-button class="form-btn" @click="openModal('add')" v-btn="'add'">添加部门</a-button> -->
            </a-form-item>
          </a-form>
        </section>
        <!-- <tableHeader>
          <a-input class="sec-input" allowClear :maxlength="20" v-model:value="searchVal" placeholder="请输入部门名称">
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
          <a-button class="search-btn basic-btn" @click="searchList">查询</a-button>
          <a-button class="add-btn warning-btn" @click="openModal('export')" v-btn="'import'">批量导入</a-button>
          <a-button class="add-btn warning-btn" @click="openModal('add')" v-btn="'add'">添加部门</a-button>
        </tableHeader> -->
        <section class="table-style" style="padding: 0">
          <a-table
            :columns="departmentColumns"
            :dataSource="dataSource"
            :pagination="pagination"
            @change="handleTableChange"
            :loading="tableLoading"
            rowKey="id"
            ref="tableRef"
            :scroll="{ x: 400 }"
          >
            <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
            <template #departmentName="{ text }">
              <span :title="text ?? '-'">{{ text ? text : '-' }}</span>
            </template>
            <template #parentDeptName="{ text }">
              <span :title="text ?? '-'">{{ text ? text : '-' }}</span>
            </template>
            <template #level="{ text }">{{ text }}级</template>
            <template #action="{ record }">
              <a
                @click="openModal('edit', record)"
                v-btn="'upadate'"
                class="table-text-color"
                style="margin-right: 16px"
                >修改</a
              >
              <a @click="openModal('delete', record)" v-btn="'delete'" class="table-text-color">删除</a>
            </template>
          </a-table>
        </section>
      </section>
      <modal
        :isAdd="isAdd"
        v-model:visible="visible"
        v-model:loading="loading"
        :type="actionType"
        :departmentList="copyDepartmentList"
        :current="current"
        @modalSubmit="modalSubmit"
      />
    </div>
  </a-card>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs, createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
import tableHeader from '@/views/components/tableHeader'
import { departmentColumns } from '../columns'
import modal from './components/modal'
import TreeVirtualList from '@/components/VirtualList/treeVirtualList'
import { paginationParams } from '@/utils/constData'
import { setTablePageFooterStyle } from '@/utils/utils'
import {
  getDepartmentOrganizational,
  getDepartmentList,
  addDepartment,
  deleteDepartment,
  editDepartment,
  importDepartment,
  verifyDepartment
} from '@/apis/businessManage'
import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import { PlusOutlined, ExclamationOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    tableHeader,
    modal,
    TreeVirtualList,
    PlusOutlined
  },
  setup() {
    const inputbox = ref(null)
    const store = useStore()
    const tableRef = ref()
    const companyName = computed(() => store.state.user.userInfo.result.enterpriseName)
    const state = reactive({
      visible: false,
      searchVal: undefined,
      selectedKeys: undefined,
      departmentColumns,
      isAdd: false,
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      departmentList: [],
      copyDepartmentList: [],
      actionType: 'add',
      current: undefined,
      loading: false,
      tableLoading: true,
      treeReload: { type: '+' },
      treeReloadType: '+',
      currentNum: 1
    })
    const getList = async () => {
      // 获取列表展示
      state.tableLoading = true
      const params = {
        searchDepartmentName: state.searchVal,
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize
      }
      const res = await getDepartmentList(params)
      state.tableLoading = false
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.pagination.current = res.pageIndex
      setTablePageFooterStyle(tableRef)
      return res
    }
    const fetchDepartmentList = async () => {
      // 获取组织框架
      const res = await getDepartmentOrganizational()
      state.departmentList = res.data.map(item => JSON.parse(JSON.stringify(item)))
      state.copyDepartmentList = res.data.map(item => item)
    }
    const handleTableChange = ({ current, pageSize }) => {
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    const searchList = () => {
      // 清空前后空格
      if (state.searchVal) {
        state.searchVal = state.searchVal.replace(/\s*/g, '')
      }
      // 查询数据
      state.pagination.current = state.searchVal ? 1 : state.currentNum
      getList()
    }
    const openModal = (type, current) => {
      const actionObjFn = {
        delete: async () => {
          const res = await verifyDepartment({ id: current.id })
          if (res.success) {
            let content = res.data.length
              ? `【${current.departmentName}】为【${res.data.join(
                  '，'
                )}】的唯一常规用印人，若删除则该印章全公司可用，是否确认？`
              : '删除后无法恢复，是否删除？'
            Modal.confirm({
              title: '确认删除！',
              content: content,
              centered: true,
              width: '25%',
              confirmLoading: state.loading,
              icon: createVNode(ExclamationOutlined),
              onOk: async () => {
                const res = await deleteDepartment({ id: current.id })
                if (res.success) {
                  cmsNotice('success', '删除成功')
                  state.treeReloadType = '-'
                  state.pagination.total %
                    (state.pagination.current * state.pagination.pageSize - state.pagination.pageSize) ===
                    1 && (state.pagination.current -= 1)
                  getList()
                  await fetchDepartmentList()
                  state.treeReload = { type: state.treeReloadType }
                }
                state.visible = false
              }
            })
          }
        }
      }
      state.actionType = type
      state.current = current
      if (type !== 'export') {
        state.isAdd = true
      } else {
        state.isAdd = false
      }
      actionObjFn[type] ? actionObjFn[type]() : (state.visible = true)
    }
    const modalSubmit = async value => {
      console.log('----676767-----', value)
      let isSuccess = false
      if (state.actionType === 'export') {
        const res = await importDepartment({ file: value })
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '上传成功')
        }
      } else if (state.actionType === 'add') {
        const res = await addDepartment({
          departmentName: value.department,
          parentId: value.nextDepart ? value.nextDepart[value.nextDepart.length - 1] : 0
        })
        if (res.success) {
          cmsNotice('success', '添加成功')
          isSuccess = true
        }
        state.treeReloadType = '+'
      } else if (state.actionType === 'edit') {
        const res = await editDepartment({
          departmentName: value.department,
          parentId: value.nextDepart ? value.nextDepart[value.nextDepart.length - 1] : 0,
          id: state.current.id
        })
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '修改成功')
        }
        state.treeReloadType = '='
      }
      state.loading = false
      if (isSuccess) {
        getList()
        await fetchDepartmentList()
        state.treeReload = { type: state.treeReloadType }
        state.visible = false
      }
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }
    onMounted(async () => {
      const { code } = await getList()
      if (+code !== 401 && +code !== 403 && +code !== 406) {
        fetchDepartmentList()
      }
    })

    return {
      ...toRefs(state),
      handleTableChange,
      searchList,
      openModal,
      modalSubmit,
      companyName,
      tableRef,
      clickFocus,
      inputbox
    }
  }
})
</script>

<style lang="less">
.wrapper {
  margin: 0 24px;
  .comfirm {
    background: #c3161c;
    color: #fff;
    border: 1px solid transparent;
    margin-right: 0;
    &:hover {
      border-color: transparent;
    }
  }
  .btn {
    border-radius: 2px;
  }
  .wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .tree-box {
      width: 194px;
      border-right: 1px solid #e9e9e9;
      :deep(.ant-tree li ul) {
        padding: 0;
      }
      .company-title {
        margin-bottom: 16px;
        width: 182px;
        height: 22px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .table-box {
      flex: 1;
      padding-left: 24px;
      overflow: auto;
    }
  }
}
.ant-modal-confirm-content {
  max-height: 90px;
  overflow-y: auto;
}
</style>
